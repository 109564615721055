import {
  IMG_UPDATE,
  IMG_SET_VIEW_DURATION,
  CLEAR_IMG
} from "../actions/imgActions";

const imgReducer = (state = { img: null, viewDuration: 12 }, action) => {
  if (!action || !action.type) return state;

  let newState = {};

  switch (action.type) {
    case IMG_UPDATE:
      newState = { ...state, img: action.img };
      break;
    case IMG_SET_VIEW_DURATION:
      newState = { ...state, viewDuration: action.viewDuration };
      break;
    case CLEAR_IMG:
      newState = { ...state, img: null };
      break;
    default:
      newState = { ...state };
      break;
  }

  return newState;
};

export default imgReducer;
