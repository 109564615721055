/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { PureComponent, Fragment } from "react"; // eslint-disable-line
import { connect } from "react-redux";

import * as style from "./styles";

import { navigate, routes, navigateWithLoadScreen } from "../../../util/router";
import { formatMinSec } from "../../../util/text-util";

import {
  imgSelector,
  imgViewDurationSelector
} from "../../../redux/selectors/imgSelectors";

import { imgSetViewDuration } from "../../../redux/actions/imgActions";
import { getCode } from "../../../redux/actions/codeActions";

import BaseView from "../BaseView";
import Button from "../../Button";

class OptionsView extends PureComponent {
  toSeconds = ticks => ticks * 3;
  fromSeconds = sec => sec / 3;

  handleGetCode = () => {
    this.props.rdxGetCode();
    navigateWithLoadScreen(routes.codes);
  };

  render() {
    const { rdxPhoto, rdxViewDuration, rdxSetViewDuration } = this.props;
    if (!rdxPhoto) {
      navigate(routes.share);
    }
    return (
      <div>
        <BaseView>
          <div css={style.img}>
            <img src={rdxPhoto} height="300" width="300" alt="" />
          </div>
          <div css={style.label}>
            How long will the image stay on the screen before being destroyed?
          </div>
          <div css={style.range}>
            <input
              className="custom-range "
              type="range"
              name="duration"
              min="1"
              max="40"
              step="1"
              value={this.fromSeconds(rdxViewDuration)}
              onChange={e => rdxSetViewDuration(this.toSeconds(e.target.value))}
            />
            {formatMinSec(rdxViewDuration)}
          </div>
          <Button text="Get Share Link" onClick={this.handleGetCode} />
        </BaseView>
      </div>
    );
  }
}

export default connect(
  state => ({
    rdxPhoto: imgSelector(state),
    rdxViewDuration: imgViewDurationSelector(state)
  }),
  dispatch => ({
    rdxSetViewDuration: duration => dispatch(imgSetViewDuration(duration)),
    rdxGetCode: payload => dispatch(getCode(payload))
  })
)(OptionsView);
