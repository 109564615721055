const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(file);
  });
};

const resizeImg = (src, maxHeight = 420, maxWidth = 420) =>
  new Promise((resolve, reject) => {
    if (src && src.length > 2000) {
      // create an img element and load the img. this will be used to load the canvas for resizing.
      const img = document.createElement("img");

      img.onload = () => {
        // create a canvas and set the props for img quality
        const canvas = document.createElement("canvas");

        const ctx = canvas.getContext("2d");
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";
        //ctx.drawImage(file, 0, 0);

        // perform calculations on height and width to preserve aspect ratio
        let height = img.height;
        let width = img.width;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.height = height;
        canvas.width = width;

        //console.log("h:", height);
        //console.log("w:", width);

        // confirm the img dimensions are valid.
        if (width > 0 && height > 0) {
          // redraw the img and output dataUrl
          ctx.drawImage(img, 0, 0, width, height);

          const result = canvas.toDataURL("image/jpeg");

          // console.log("result: ", result.length);

          resolve(result);
        } else {
          reject("Failed to load image.");
        }
      };

      img.onerror = () => reject(img.error);
      img.src = src;
    } else {
      reject("Failed to load file OR img too small.");
    }
  });

const resizeImgFromFile = (file, maxHeight = 420, maxWidth = 420) =>
  new Promise((resolve, reject) => {
    // create a file reader and read the img file.
    try {
      const fr = new FileReader();
      fr.onload = e => {
        const src = e.target.result;
        //console.log("src", src.length);
        // make sure the file has loaded and it is at least 26x26 px^2
        resizeImg(src, maxHeight, maxWidth).then(
          dataUrl => resolve(dataUrl),
          error => reject(error)
        );
      };

      fr.onerror = () => reject(fr.error);
      fr.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
    //img.src = window.URL.createObjectURL(file);
  });

const rotateImg90DegInPlace = (img, cw = true) => {
  const degrees = cw ? 90 : -90;
  const canvas = document.createElement("canvas");

  canvas.height = img.height;
  canvas.width = img.width;

  const ctx = canvas.getContext("2d");
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(img, 0, 0);

  ctx.clearRect(0, 0, img.width, img.height);
  ctx.save();
  canvas.width = img.height;
  canvas.height = img.width;

  ctx.translate(0, -img.height / 2);

  ctx.rotate((degrees * Math.PI) / 180);
  if (cw) {
    ctx.drawImage(img, img.height / 2, -img.height);
  } else {
    ctx.drawImage(img, -img.width - img.height / 2, 0);
  }
  ctx.restore();

  img.src = canvas.toDataURL("image/png");
};

export { rotateImg90DegInPlace, resizeImgFromFile, resizeImg, getBase64 };
