import routes from "../config/routes";

let _history = {};

const init = history => (_history = history);

const navigate = path => _history.push(path);

const navigateWithLoadScreen = path => {
  _history.push(routes.loading, { next: path });
};

const getLocationState = () => _history && _history.location.state;

export { init, navigate, routes, navigateWithLoadScreen, getLocationState };
