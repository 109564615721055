/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react"; // eslint-disable-line
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import tacSelector from "../../../redux/selectors/tacSelectors";
import routes from "../../../config/routes";

import * as style from "./styles";
import logo from "../../../assets/img/logo.png";

const BaseView = props => (
  <div css={style.container}>
    <div css={style.title}>SeeMeOnce</div>
    {props.showLogo && (
      <div>
        <img src={logo} alt="" />
      </div>
    )}
    <div css={style.childContainer}>{props.children}</div>
    {props.showTac && ( // hide on when keyboard is open on mobile phones
      <div css={style.link} style={props.tacBottom && { position: "absolute" }}>
        <Link to={routes.tac}>{"Terms & Conditions"}</Link>
      </div>
    )}
  </div>
);

export default connect(state => ({ showTac: tacSelector(state) }))(BaseView);
