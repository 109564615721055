const selectViewImg = rdxState => {
  if (rdxState && rdxState.viewImgReducer) {
    const state = rdxState.viewImgReducer;
    const progress =
      state.initialViewTime > 0
        ? (100 * state.viewTime) / state.initialViewTime
        : 0;
    return {
      img: state.img,
      viewTime: state.viewTime,
      progress
    };
  }
};

export { selectViewImg };
