import { theme, flex } from "../../commonStyle";

const img = {
  ...flex,
  position: "relative",
  img: {
    border: "1px solid gray",
    borderRadius: "20px"
  }
};

const label = {
  ...flex,
  justifyContent: "center",
  textAlign: "center",
  padding: "5px",
  color: theme.fg
};

const range = {
  ...flex,
  width: "304px",
  padding: "5px",
  margin: "10px 0px"
};

const copied = {
  minHeight: "36px",
  width: "100%",
  textAlign: "center",
  fontSize: "1rem",
  fontStyle: "italic",
  color: "orange",
  padding: "5px"
};

const time = {
  position: "absolute",
  right: "15px",
  bottom: "10px",
  color: theme.fg,
  borderRadius: "30%",
  padding: "2px",
  paddingTop: "0px",
  textAlign: "center",
  backgroundColor: " rgba(0, 0, 0, 0.20)"
};

export { img, range, label, copied, time };
