const flex = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "top"
};

const container = {
  //position: "absolute",
  backgroundColor: "#282c34",
  minHeight: "100vh",
  //minWidth: "100vw",
  width: "100%",
  height: "100%",
  ...flex,
  fontSize: "calc(10px + 2vmin)",
  color: "white"
};

const title = {
  fontFamily: "AguafinaScript",
  fontSize: "3rem",
  marginTop: "20px"
};

const link = {
  position: "relative",
  bottom: "0px",
  width: "100%",
  height: "40px",
  ...flex,
  justifyContent: "center",
  zIndex: 10001,
  a: {
    color: "white",
    textDecoration: "none"
  }
};

const childContainer = {
  ...flex,
  marginTop: "20px",
  width: "100%",
  overflowX: "hidden"
  // minHeight: "200px"
};

export { container, title, link, childContainer };
