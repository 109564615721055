/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react"; // eslint-disable-line

import * as style from "./styles";

const Button = ({ text, onClick }) => (
  <button css={style.btn} onClick={onClick}>
    {text}
  </button>
);

export default Button;
