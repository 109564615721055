const img = {
  width: "100%",
  minWidth: "300px",
  maxWidth: "420px",
  minHeight: "300px",
  maxHeight: "420px",
  borderRadius: "10px",
  border: "1px solid #474747",
  marginBottom: "2px"
};

const bar = {
  width: "100%",
  maxWidth: "420px"
  //   marginBottom: "50px"
};

const time = {
  marginTop: "20px",
  textAlign: "center",
  fontSize: "2rem",
  color: "orange"
};

const screenOverlay = {
  position: "fixed",
  minHeight: "100vh",
  minWidth: "100vw",
  width: "100%",
  height: "100%",
  zIndex: 10000
};

export { img, bar, time, screenOverlay };
