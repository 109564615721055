import { theme, flex } from "../../commonStyle";

const img = {
  img: {
    border: "1px solid gray",
    borderRadius: "20px"
  }
};

const label = {
  ...flex,
  justifyContent: "center",
  textAlign: "center",
  padding: "5px",
  color: theme.fg
};

const range = {
  ...flex,
  width: "304px",
  padding: "5px",
  margin: "10px 0px"
};

export { img, range, label };
