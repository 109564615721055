/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import * as style from "./styles";
import { navigate } from "../../../util/router";
import routes from "../../../config/routes";
import { resizeImgFromFile, resizeImg } from "../../../util/file-util";

import { imgUpdate } from "../../../redux/actions/imgActions";

import BaseView from "../BaseView";
import Input from "../../Input";
import Button from "../../Button";
import ImgCrop from "../../ImgCrop";

const ShareButton = () => (
  <Fragment>
    <div css={style.divider}>{"--OR--"}</div>
    <Button
      text="Share Image Annonimously"
      onClick={() => navigate(routes.share)}
    />
  </Fragment>
);

const ViewButton = ({ code }) => (
  <Fragment>
    <br />
    <Button
      text="View Image"
      onClick={() => navigate(routes.withWildCard(routes.loadImage, { code }))}
    />
  </Fragment>
);

const ViewOrSharePrompt = ({ code, showViewBtn, onCodeChange }) => (
  <div>
    <Input
      title="Have A View Code?"
      placeholder="Enter Code Here"
      onChange={onCodeChange}
      inputStyle={{ fontSize: "14px" }}
    />
    {showViewBtn ? <ViewButton code={code} /> : <ShareButton />}
  </div>
);

const ShareOptions = ({ uploadInput, handleImage, askForImage }) => (
  <div css={style.flex}>
    <div css={style.label}>Choose How To Share</div>
    <Button text="Share From File" onClick={askForImage} />
    <div css={style.divider}>{"--OR--"}</div>
    <Button text="Take A Photo" onClick={() => navigate(routes.camera)} />
    <input
      type="file"
      ref={uploadInput}
      onChange={handleImage}
      accept="image/*"
      hidden
    />
  </div>
);

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showViewBtn: false,
      code: "",
      imageToCrop: null
    };

    this.uploadInput = React.createRef();

    this.cropInfo = {};
  }

  onCodeChange = code => this.setState({ code, showViewBtn: !!code });

  // preprocesses the image before cropping begins.
  handleImage = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      //console.log("image", file);
      // resize the image to max 420x420
      resizeImgFromFile(file).then(dataUrl =>
        this.setState({ imageToCrop: dataUrl })
      );
    }
  };

  // trigers the imput element. and opens the file prompt.
  askForImage = () => {
    this.uploadInput.current.click();
  };

  handleCroppedImage = dataUrl => {
    //console.log("cropped img: ", dataUrl);
    resizeImg(dataUrl, 420, 420).then(dataUrlLg => {
      this.setState(
        {
          imageToCrop: null
        },
        () => {
          this.props.rdxUpdateImg(dataUrlLg);
          navigate(routes.options);
        }
      );
    });
  };

  handleCropCancel = () => {
    // this is BAD! but solves the problem of not being able to load an
    // img after you cancel a crop
    window.location.reload(true);
  };

  render() {
    const { showViewBtn, code, imageToCrop } = this.state;
    //console.log("image-render", imageToCrop);
    return (
      <Fragment>
        {imageToCrop && (
          <ImgCrop
            dataUrl={this.state.imageToCrop}
            onSave={dataUrl => this.handleCroppedImage(dataUrl)}
            onCancel={() => this.handleCropCancel()}
          />
        )}

        <BaseView showLogo>
          <Switch>
            <Route
              path={routes.share}
              render={props => (
                <ShareOptions
                  uploadInput={this.uploadInput}
                  handleImage={this.handleImage}
                  askForImage={this.askForImage}
                />
              )}
            />
            <Route
              exact
              path={routes.home}
              render={props => (
                <ViewOrSharePrompt
                  showViewBtn={showViewBtn}
                  code={code}
                  onCodeChange={this.onCodeChange}
                />
              )}
            />
          </Switch>
        </BaseView>
      </Fragment>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    rdxUpdateImg: dataUrl => dispatch(imgUpdate(dataUrl))
  })
)(Home);
