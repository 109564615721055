import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./index.css";
import App from "./App.jsx";
import * as serviceWorker from "./serviceWorker";

import store from "./redux/store";
import isMobile, { isApple } from "./util/isMobile";

import "bootstrap/dist/css/bootstrap.css";

const RootApp = () => (
  <Provider store={store}>
    <Router>
      <Route path="/" component={App} />
    </Router>
  </Provider>
);

const rootElement = document.getElementById("root");

// detect if user is using a mobile browser.
window.isMobile = isMobile();
window.isApple = isApple();

ReactDOM.render(<RootApp />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
