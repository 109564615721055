/** @jsx jsx */
import { jsx } from "@emotion/core";

//import React from "react";

import * as style from "./styles";
import { routes, navigate } from "../../../util/router";

const TermsAndConditions = () => (
  <div css={style.container}>
    <div css={style.title}>SeeMeOnce</div>
    <div css={style.text}>
      <p>
        This app allows you to anonymously share images in a simple and straight
        forward way. Once an image is delivered it can only be seen for the
        amount of time specified by you. It is deleted from our servers as soon
        as it goes out to it's destination. It is destroyed immediately after
        the allotted time expires on recipients device. SeeMeOnce is a free
        service. As such it comes with no warranty of any kind.
        <br />
        Terms of use:
      </p>
      <ul>
        <li>
          We do not store any of your data beyond the time of delivering your
          image to it's recipient. Image is deleted permanently from our server
          when delivered.
        </li>
        <li>
          You must not use this service to distribute illegal content of any
          kind.
        </li>
        <li>
          You are fully responsible for the images you share. SeeMeOnce carries
          absolutely no responsibility for the content you share or the way in
          which you use this service.
        </li>
        <li>Use this service responsibly.</li>
      </ul>
      <p>You can only use the app if you agree with the rules listed here.</p>
    </div>
    <div>
      <i
        className="fa fa-check fa-2x"
        aria-hidden="true"
        onClick={() => navigate(routes.home)}
      />
    </div>
  </div>
);

export default TermsAndConditions;
