import { loadFromLocalStorage, saveToLocalStorage } from "../util/browser-util";
import http from "../util/http-util";
import config from "../config/config";
import log from "./logging-service";

// eslint-disable-next-line no-unused-vars
const mockGetImg = code => {
  return new Promise((resolve, reject) => {
    const appData = loadFromLocalStorage("see_me_once");
    //console.log("mockGetImg", appData);
    if (appData && code in appData) {
      const data = appData[code];
      const img = {
        data: {
          img: data.Img,
          viewDuration: data.ViewDuration
        }
      };
      //console.log("mockGetImg->img", img);
      setTimeout(() => resolve(img), 1000);

      //delete appData[code];

      saveToLocalStorage("see_me_once", appData);
    } else {
      reject("Image Not Found!");
    }
  });
};

const getImgData = async code => {
  let result = null;
  if (code) {
    //debugger;
    let response = null;
    if (!config.useMockData) {
      response = await http.get(`${config.api.img.path}&key=${code}`);
    } else {
      response = await mockGetImg(code);
    }
    // console.log("fetchImg", response);
    if (
      response &&
      response.data &&
      response.data.img &&
      response.data.viewDuration
    ) {
      result = {
        img: `data:image/jpeg;base64,${response.data.img}`,
        viewTime: response.data.viewDuration
      };
    }
  } else {
    log.warning("[imgService.getImgData()]: no code");
  }
  return result;
};

export { getImgData };
