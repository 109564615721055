export const ChangeThemeColor = color => {
  document
    .querySelector("meta[name=theme-color]")
    .setAttribute("content", color);
  document
    .querySelector("meta[name=msapplication-navbutton-color]")
    .setAttribute("content", color);
  document
    .querySelector("meta[name=apple-mobile-web-app-status-bar-style]")
    .setAttribute("content", color);
};

export const deselect = () => {
  const selection =
    "getSelection" in window
      ? window.getSelection()
      : "selection" in document
      ? document.selection
      : null;
  if ("removeAllRanges" in selection) selection.removeAllRanges();
  else if ("empty" in selection) selection.empty();
};
