import { theme, flex } from "../commonStyle";

const container = {};

const flexRow = {
  ...flex,
  zIndex: "20",
  marginTop: "15px",
  color: theme.fg
};

export { container, flexRow };
