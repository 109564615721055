const appBaseUrl = "https://localhost:3000";
const apiBaseUrl = "https://localhost:5000/api";

const config = {
  isDebugMode: true,
  useMockData: true,
  baseUrlFE: `${appBaseUrl}`,
  baseUrlBE: `${apiBaseUrl}`,
  api: {
    code: {
      path: `${apiBaseUrl}/code`,
      method: "POST"
    },
    img: {
      path: `${apiBaseUrl}/img`,
      method: "GET"
    }
  }
};

export default config;
