import { theme, flex } from "../../commonStyle";

const container = {
  ...flex,
  justifyContent: "center",
  backgroundColor: theme.bg,
  height: "100vh",
  color: theme.fg,
  fontSize: "calc(16px + 2vmin)",
  fontFamily: "AllertaStencil"
};

export { container };
