/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { Fragment } from "react"; // eslint-disable-line
import { connect } from "react-redux";

import * as style from "./styles";

import { navigateWithLoadScreen, routes } from "../../../util/router";
import { actions } from "../../../redux/actions/viewImgActions";

const LoadImage = props => {
  if (props.match && props.match.params && props.match.params.code) {
    const code = props.match.params.code;
    // console.log("received code", code);
    props.rdxFetchImg(code);
    navigateWithLoadScreen(routes.view);
  }

  return <div css={style.container} />;
};

export default connect(
  state => ({}),
  dispatch => ({
    rdxFetchImg: code => dispatch(actions.fetchImage(code))
  })
)(LoadImage);
