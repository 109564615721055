import { flex, label as _label } from "../../commonStyle";

const divider = {
  ...flex,
  justifyContent: "center",
  padding: "10px"
};

const label = {
  ..._label,
  marginBottom: "10px"
};

export { divider, label, flex };
