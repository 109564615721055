/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { Fragment } from "react"; // eslint-disable-line
import { connect } from "react-redux";

import * as style from "./styles";

import { routes, navigate, getLocationState } from "../../../util/router";

import { isLoadingSelector } from "../../../redux/selectors/loadingSelectors";

import spinner from "../../../assets/img/dual-ring-spinner-0.5s-200px.svg";

const LoadingView = props => {
  if (!props.rdxIsLoading) {
    const locationState = getLocationState();
    if (locationState && locationState.next) {
      navigate(locationState.next);
    } else {
      navigate(routes.home);
    }
  }

  return (
    <div css={style.container}>
      <div>Loading..</div>
      <div>
        <img src={spinner} alt="" />
      </div>
    </div>
  );
};

export default connect(state => ({
  rdxIsLoading: isLoadingSelector(state)
}))(LoadingView);
