import { theme } from "../commonStyle";

const btn = {
  backgroundColor: theme.accent,
  color: theme.fg,
  width: "304px",
  height: "50px",
  borderRadius: "8px",
  fontSize: "1.2rem",
  fontFamily: "AllertaStencil",
  borderStyle: "none",
  "&:active": {
    border: `1px solid ${theme.fg}`,
    borderRadius: "8px"
  },
  "&:focus": {
    border: `1px solid ${theme.fg}`,
    borderRadius: "8px",
    outline: "none"
  }
};

export { btn };
