/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { Component } from "react"; // eslint-disable-line
import ReactCrop from "react-image-crop";

import { rotateImg90DegInPlace } from "../../util/file-util";
import * as style from "./styles";

import "./img-crop.css";
import "react-image-crop/dist/ReactCrop.css";

class ImgCrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: {
        aspect: 1,
        x: 20,
        y: 10,
        height: 50
      }
    };

    this.cropInfo = {};
  }

  getCroppedImg = (image, pixelCrop, fileName) => {
    //debugger;
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    //As Base64 string
    return canvas.toDataURL("image/jpeg");

    // As a blob
    // return new Promise((resolve, reject) => {
    //   canvas.toBlob(file => {
    //     file.name = fileName;
    //     resolve(file);
    //   }, 'image/jpeg');
    // });
  };

  onRotate = () => {
    if (this.cropInfo.image) {
      rotateImg90DegInPlace(this.cropInfo.image, false);
    }
  };

  render() {
    return (
      <div>
        <div className="img-resize-mask" />,
        <div className="img-resize-container">
          <ReactCrop
            src={this.props.dataUrl}
            crop={this.state.crop}
            keepSelection={true}
            onChange={crop => {
              this.setState({ crop });
            }}
            onComplete={(crop, pixelCrop) => {
              this.cropInfo = { ...this.cropInfo, pixelCrop };
              // console.log("cropInfo: ", this.cropInfo);
            }}
            onImageLoaded={(image, pixelCrop) => {
              this.cropInfo = { image, pixelCrop };
            }}
          />
          <div css={style.flexRow}>
            <i className="fas fa-sync fa-2x" onClick={() => this.onRotate()} />
          </div>
          <div css={style.flexRow}>
            <div>
              <button
                className="btn btn-success btn-lg"
                onClick={() =>
                  this.props.onSave(
                    this.getCroppedImg(
                      this.cropInfo.image,
                      this.cropInfo.pixelCrop
                    )
                  )
                }
              >
                <i className="fas fa-plus fa-sm" />
                Save
              </button>
              <button
                className="btn btn-danger btn-lg"
                onClick={this.props.onCancel}
              >
                <i className="fas fa-times fa-sm" />
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImgCrop;
