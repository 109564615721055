import {
  GET_CODE_START,
  GET_CODE_COMPLETE,
  GET_CODE_ERROR,
  CLEAR_CODE
} from "../actions/codeActions";

const codeReducer = (state = { code: null, isLoading: false }, action) => {
  if (!action || !action.type) return state;

  let newState = {};

  switch (action.type) {
    case GET_CODE_START:
      newState = { ...state, code: null, isLoading: true };
      break;
    case GET_CODE_COMPLETE:
      newState = { ...state, code: action.code, isLoading: false };
      break;
    case GET_CODE_ERROR:
      newState = { ...state, codeError: action.msg, isLoading: false };
      break;
    case CLEAR_CODE:
      newState = { ...state, code: null, isLoading: false };
      break;
    default:
      newState = { ...state };
      break;
  }

  return newState;
};

export default codeReducer;
