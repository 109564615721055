import { TAC_HIDE, TAC_SHOW } from "../actions/tacActions";

const imgReducer = (state = { visible: true }, action) => {
  if (!action || !action.type) return state;

  let newState = {};
  if (window.isMobile) {
    switch (action.type) {
      case TAC_HIDE:
        newState = { ...state, visible: false };
        break;
      case TAC_SHOW:
        newState = { ...state, visible: true };
        break;
      default:
        newState = { ...state };
        break;
    }
  } else {
    newState = state;
  }

  return newState;
};

export default imgReducer;
