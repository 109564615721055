/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react"; // eslint-disable-line
import { connect } from "react-redux";

import { tacShow, tacHide } from "../../redux/actions/tacActions";

import * as style from "./styles";

const Input = ({
  title,
  value,
  placeholder,
  size,
  inputStyle,
  onChange,
  onClick,
  getRef,
  inputProps,
  disableTacHidding,
  rdxTacShow,
  rdxTacHide
}) => (
  <div css={style.container}>
    <div css={size === "sm" ? style.labelSm : style.label}>
      <span>{title}</span>
    </div>
    <div css={style.input}>
      <input
        style={inputStyle}
        type="text"
        placeholder={placeholder}
        {...(() => value && { value })()}
        onChange={e => (onChange ? onChange(e.target.value) : () => null)}
        onClick={onClick}
        onFocus={disableTacHidding ? () => null : rdxTacHide}
        onBlur={disableTacHidding ? () => null : rdxTacShow}
        ref={input => getRef && getRef(input)}
        {...inputProps}
      />
    </div>
  </div>
);

export default connect(
  state => ({}),
  dispatch => ({
    rdxTacShow: () => dispatch(tacShow()),
    rdxTacHide: () => dispatch(tacHide())
  })
)(Input);
