import { getCode as fetchCode } from "../../services/codeSerice";
import { payloadSelector } from "../selectors/imgSelectors";

const GET_CODE_START = "GET_CODE_START";
const GET_CODE_COMPLETE = "GET_CODE_COMPLETE";
const GET_CODE_ERROR = "GET_CODE_ERROR";
const CLEAR_CODE = "CLEAR_CODE";

const getCode = () => {
  return async (dispatch, getState) => {
    dispatch(getCodeStart());

    const rdxState = getState();
    const payload = payloadSelector(rdxState);

    // console.log("payload", payload);

    const code = await fetchCode(payload);

    if (code) {
      dispatch(getCodeComplete(code));
    } else {
      dispatch(getCodeError("Failed to get Code!"));
    }
  };
};

const getCodeStart = () => ({
  type: GET_CODE_START
});

const getCodeComplete = code => ({
  type: GET_CODE_COMPLETE,
  code
});

const getCodeError = msg => ({
  type: GET_CODE_ERROR,
  msg
});

const clearCode = () => ({
  type: CLEAR_CODE
});

export {
  GET_CODE_START,
  GET_CODE_COMPLETE,
  GET_CODE_ERROR,
  CLEAR_CODE,
  getCode,
  clearCode
};
