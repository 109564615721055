const saveToLocalStorage = (key, data) => {
  try {
    if (key && data) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  } catch (error) {
    console.warning(`[browser-util.saveToLocalStorage]: ${error}`);
  }
};

const loadFromLocalStorage = key => {
  let result = undefined;

  try {
    if (key) {
      const data = localStorage.getItem(key);
      if (data) {
        result = JSON.parse(data);
      }
    }
  } catch (error) {
    console.warning(`[browser-util.loadFromLocalStorage]: ${error}`);
  }

  return result;
};

export { saveToLocalStorage, loadFromLocalStorage };
