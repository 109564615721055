const toggle = {
  color: "gray",
  textAlign: "center",
  marginBottom: "10px",
  span: {
    padding: "5px"
  }
};

const toggleActive = {
  color: "orange"
};

export { toggle, toggleActive };
