const appBaseUrl = "https://seemeonce.net";
const apiBaseUrl = "https://see-me-once.azurewebsites.net/api";

const config = {
  isDebugMode: false,
  useMockData: false,
  baseUrlFE: `${appBaseUrl}`,
  baseUrlBE: `${apiBaseUrl}`,
  api: {
    code: {
      path: `${apiBaseUrl}/ImagePush?code=IaS1Li5YcJP0TEwVuH7YMVVsIzZabevrNU1LtVMVeELUrCsCYMW8SQ==`,
      method: "POST"
    },
    img: {
      path: `${apiBaseUrl}/ImagePull?code=DujTlQjiGXrSNvOpe7rF7AF/S8o5qS390hq5ZHbmzTJKalbl9dB21A==`,
      method: "GET"
    }
  }
};

export default config;
