/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { PureComponent, Fragment } from "react"; // eslint-disable-line
import { connect } from "react-redux";

import * as style from "./styles";

import { navigate, routes } from "../../../util/router";
import { formatMinSec } from "../../../util/text-util";

import { actions } from "../../../redux/actions/viewImgActions";
import { selectViewImg } from "../../../redux/selectors/viewImgSelectors";

import BaseView from "../BaseView";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

class ImageView extends PureComponent {
  state = {
    timer: null,
    windowWidth: getWidth() > 420 ? 420 : getWidth()
  };

  componentDidMount() {
    const { img, viewTime } = this.props.rdxPayload;
    if (!img || viewTime <= 0) {
      navigate(routes.home);
    } else {
      this.setState({
        windowWidth: getWidth() > 420 ? 420 : getWidth()
      });
      const timer = setInterval(() => {
        this.props.rdxCountDown(1);
      }, 1000);
      this.setState({ timer });
    }
  }

  render() {
    const { rdxPayload } = this.props;
    const { img, progress, viewTime } = rdxPayload;

    // console.log("viewImage", rdxPayload);
    if (!img || viewTime <= 0) {
      clearInterval(this.state.timer);
      navigate(routes.home);
    }

    return (
      <Fragment>
        <div css={style.screenOverlay} />
        <BaseView tacBottom>
          <div css={style.img}>
            <img src={img} alt="" width={this.state.windowWidth} />
          </div>
          <div css={style.bar}>
            <div className="progress">
              <div className="progress-bar" style={{ width: `${progress}%` }} />
            </div>
            <div css={style.time}>{formatMinSec(viewTime)}</div>
          </div>
        </BaseView>
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    rdxPayload: selectViewImg(state)
  }),
  dispatch => ({
    rdxCountDown: elapsedTime => dispatch(actions.imgCountDown(elapsedTime))
  })
)(ImageView);
