/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { Component, Fragment } from "react"; // eslint-disable-line
import { connect } from "react-redux";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import { Link } from "react-router-dom";

import log from "../../../services/logging-service";

import * as style from "./styles";

import { routes } from "../../../util/router";
import { imgSelector } from "../../../redux/selectors/imgSelectors";
import { imgUpdate } from "../../../redux/actions/imgActions";

import "react-html5-camera-photo/build/css/index.css";
import "./camera.css";

const Toggle = ({ mode, onClick }) => {
  return (
    <div css={style.toggle}>
      <span
        css={mode === FACING_MODES.USER && style.toggleActive}
        onClick={() => onClick(FACING_MODES.USER)}
      >
        FRONT
      </span>
      <span>|</span>
      <span
        css={mode === FACING_MODES.ENVIRONMENT && style.toggleActive}
        onClick={() => onClick(FACING_MODES.ENVIRONMENT)}
      >
        BACK
      </span>
    </div>
  );
};

class CameraView extends Component {
  constructor(props) {
    super(props);
    this.state = { cameraOn: true, cameraFacingMode: FACING_MODES.ENVIRONMENT };
  }

  onTakePhoto(dataUri) {
    //console.log('photo taken', dataUri);
    this.setState({ cameraOn: false }, () => this.props.rdxUpdateImg(dataUri));
  }

  onCameraError(error) {
    log.exception(error);
  }

  componentDidMount() {
    // changing the theme to black makes sure the title bar is black like the background.
    //ChangeThemeColor("#000");
  }

  render() {
    // console.log("camera-props", this.props);
    const { cameraOn, cameraFacingMode } = this.state;
    const dataUri = this.props.rdxImg;

    return (
      <div className="oui-camera-container">
        {cameraOn ? (
          <Fragment>
            {(window.isMobile || window.isApple) && (
              <Toggle
                mode={cameraFacingMode}
                onClick={mode => this.setState({ cameraFacingMode: mode })}
              />
            )}
            <Camera
              onTakePhoto={dataUri => {
                this.onTakePhoto(dataUri);
              }}
              onCameraError={error => {
                this.onCameraError(error);
              }}
              idealFacingMode={cameraFacingMode}
              idealResolution={{ width: 420, height: 420 }}
              // idealResolution={{ width: 640, height: 480 }}
              imageType={IMAGE_TYPES.JPG}
              imageCompression={0.97}
              isMaxResolution={false}
              isImageMirror={false}
              isDisplayStartCameraError={true}
              sizeFactor={1}
              // onCameraStart={stream => {
              // 	//this.onCameraStart(stream);
              // }}
              onCameraStop={() => {
                //ChangeThemeColor("#D80000");
                // clear up all timers set by this component before it's unmounted
                // var id = window.setTimeout(function() {}, 0);
                // console.log('clearing timers, starting at', id);
                // while (id && id >= 0) {
                // 	//window.clearTimeout(id--);
                // }
                //this.props.onReady();
              }}
            />
          </Fragment>
        ) : (
          <div className="react-html5-camera-photo">
            <img src={dataUri} alt="" />
            <div className="oui-camera-preview-ctrls">
              <i
                className="fas fa-4x fa-undo-alt"
                onClick={() => this.setState({ cameraOn: true })}
              />
              <Link to={{ pathname: routes.options }}>
                <i className="far fa-4x fa-check-circle" />
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    rdxImg: imgSelector(state)
  }),
  dispatch => ({
    rdxUpdateImg: dataUrl => dispatch(imgUpdate(dataUrl))
  })
)(CameraView);
