import http from "../util/http-util";
import config from "../config/config";
import log from "./logging-service";
import { loadFromLocalStorage, saveToLocalStorage } from "../util/browser-util";

// eslint-disable-next-line no-unused-vars
const mockCode = payload =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      const code = `FAS99XX000B${Math.floor(Math.random() * 10000)}`;
      const appData = loadFromLocalStorage("see_me_once") || {};

      appData[code] = payload;
      saveToLocalStorage("see_me_once", appData);
      resolve({ data: { code } });
    }, 1000)
  );

const getCode = async payload => {
  let result = null;
  // console.log("[codeService.getCode()].payload", payload);
  let response = null;
  if (payload && payload.Img && payload.ViewDuration) {
    const img = payload.Img.split(",")[1];
    const _payload = { ...payload, Img: img };
    if (!config.useMockData) {
      response = await http.post(
        config.api.code.path,
        JSON.stringify(_payload),
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
    } else {
      response = await mockCode(_payload);
    }
    //debugger;

    if (response && response.data && response.data.code) {
      result = response.data.code;
    }
  } else {
    log.warning("[codeService.getCode()]: invalid payload");
  }

  return result;
};

export { getCode };
