/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { PureComponent, Fragment } from "react"; // eslint-disable-line
import { connect } from "react-redux";

import * as style from "./styles";

import { navigate, routes } from "../../../util/router";
import { deselect } from "../../../util/dom-util";
import { formatMinSec } from "../../../util/text-util";
import config from "../../../config/config";

import {
  imgSelector,
  imgViewDurationSelector
} from "../../../redux/selectors/imgSelectors";
import { codeSelector } from "../../../redux/selectors/codeSelectors";

import { clearCode } from "../../../redux/actions/codeActions";
import { clearImg } from "../../../redux/actions/imgActions";

import BaseView from "../BaseView";
import Button from "../../Button";
import Input from "../../Input";

class CodeView extends PureComponent {
  state = { copied: "" };
  copyToClipboard = (ref, name) => {
    ref.select();
    document.execCommand("copy");
    deselect();
    this.setState({ copied: `Your ${name} was coppied to the clipboard!` });
  };

  inputStyle = {
    fontSize: "14px",
    fontStyle: "italic",
    fontWeight: "bold"
  };

  render() {
    const { rdxPhoto, rdxCode, rdxViewDuration } = this.props;
    if (!rdxPhoto || !rdxCode) {
      navigate(routes.home);
    }
    const { copied } = this.state;
    const url = `${config.baseUrlFE}${routes.withWildCard(routes.loadImage, {
      code: rdxCode
    })}`;
    return (
      <div>
        <BaseView>
          <div css={style.img}>
            <div css={style.time}>{formatMinSec(rdxViewDuration)}</div>
            <img src={rdxPhoto} height="300" width="300" alt="" />
          </div>
          <Input
            title="Your Share Code"
            size="sm"
            inputStyle={this.inputStyle}
            value={rdxCode}
            onClick={e => this.copyToClipboard(this.codeRef, "Code")}
            getRef={ref => (this.codeRef = ref)}
            disableTacHidding
          />
          <Input
            title="Your Share Link"
            size="sm"
            inputStyle={this.inputStyle}
            value={url}
            onClick={e => this.copyToClipboard(this.urlRef, "Share Link")}
            getRef={ref => (this.urlRef = ref)}
            disableTacHidding
          />
          <div css={style.copied}>{copied}</div>
          <Button
            text="Share Another Image"
            onClick={() => navigate(routes.share)}
          />
        </BaseView>
      </div>
    );
  }
}

export default connect(
  state => ({
    rdxPhoto: imgSelector(state),
    rdxCode: codeSelector(state),
    rdxViewDuration: imgViewDurationSelector(state)
  }),
  dispatch => ({
    rdxClearImg: () => dispatch(clearImg()),
    rdxClearCode: () => dispatch(clearCode())
  })
)(CodeView);
