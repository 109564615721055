const routes = {
  home: "/",
  view: "/view",
  options: "/options",
  camera: "/camera",
  share: "/share",
  loading: "/loading",
  codes: "/codes",
  loadImage: "/v/:code",
  tac: "/terms-and-conditions",
  /**
   * @param {string} route: the route to operate on
   * @param {wildcards} wildcards: key value pair of wildcards and their values {wildcard: value, ..}
   */
  withWildCard: (route, wildcards) => {
    const parts = route.split(":");
    if (parts.length > 1) {
      return parts
        .map(p => p.replace("/", ""))
        .reduce((url, wildcard) => {
          let result = url;
          if (wildcard in wildcards) {
            result = `${url}${wildcards[wildcard]}`;
          }
          return result;
        }, parts[0]);
    }
  }
};

export default routes;
