import { actionsTypes } from "../actions/viewImgActions";

const viewImgReducer = (
  state = { img: null, viewTime: 0, initialViewTime: 0, isLoading: false },
  action
) => {
  if (!action || !action.type) return state;

  let newState = {};

  switch (action.type) {
    case actionsTypes.IMG_LOAD_START:
      newState = { ...state, isLoading: true };
      break;
    case actionsTypes.IMG_LOAD_COMPLETE:
      newState = {
        ...state,
        img: action.img,
        viewTime: action.viewTime,
        initialViewTime: action.viewTime,
        isLoading: false
      };
      break;
    case actionsTypes.IMG_LOAD_ERROR:
      newState = { ...state, errorMsg: action.errorMsg, isLoading: false };
      break;
    case actionsTypes.CLEAR_LOADED_IMG:
      newState = {
        ...state,
        img: null,
        viewTime: 0,
        initialViewTime: 0,
        isLoading: false
      };
      break;
    case actionsTypes.IMG_COUNT_DOWN:
      newState = {
        ...state,
        viewTime: action.viewTime
      };
      break;
    default:
      newState = state;
      break;
  }

  return newState;
};

export default viewImgReducer;
