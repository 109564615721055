const imgSelector = rdxState =>
  rdxState && rdxState.imgReducer && rdxState.imgReducer.img;

const imgViewDurationSelector = rdxState =>
  rdxState && rdxState.imgReducer && rdxState.imgReducer.viewDuration;

const payloadSelector = rdxState =>
  rdxState &&
  rdxState.imgReducer && {
    Img: rdxState.imgReducer.img,
    ViewDuration: rdxState.imgReducer.viewDuration
  };

export default imgSelector;

export { imgSelector, imgViewDurationSelector, payloadSelector };
