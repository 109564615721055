import { getImgData } from "../../services/imgService";

const IMG_LOAD_START = "IMG_LOAD_START";
const IMG_LOAD_COMPLETE = "IMG_LOAD_COMPLETE";
const IMG_LOAD_ERROR = "IMG_LOAD_ERROR";
const IMG_COUNT_DOWN = "IMG_COUNT_DOWN";
const CLEAR_LOADED_IMG = "CLEAR_LOADED_IMG";

const imgLoadStart = () => ({
  type: IMG_LOAD_START,
  isLoading: true
});

const imgLoadComplete = payload => ({
  type: IMG_LOAD_COMPLETE,
  isLoading: false,
  img: payload.img,
  viewTime: payload.viewTime
});

const imgLoadError = errorMsg => ({
  type: IMG_LOAD_ERROR,
  isLoading: false,
  errorMsg
});

const clearLoadedImg = () => ({
  type: CLEAR_LOADED_IMG
});

const imgUpdateCountdown = viewTime => ({
  type: IMG_COUNT_DOWN,
  viewTime
});

const imgCountDown = elapsedSeconds => {
  return (dispatch, getState) => {
    const rdxState = getState();
    if (rdxState.viewImgReducer) {
      const viewTime = rdxState.viewImgReducer.viewTime;
      const remainingTime = viewTime - elapsedSeconds;
      if (viewTime > 0) {
        if (remainingTime > 0) {
          dispatch(imgUpdateCountdown(remainingTime));
        } else {
          dispatch(imgUpdateCountdown(0));
        }
      } else {
        dispatch(clearLoadedImg());
      }
    }
  };
};

const fetchImage = code => {
  return async dispatch => {
    dispatch(imgLoadStart());
    try {
      const payload = await getImgData(code);
      //console.log("[fetchImage] -> payload:", payload);

      if (payload) {
        //console.log("[fetchImage] -> if:", payload);
        dispatch(imgLoadComplete(payload));
      } else {
        dispatch(imgLoadError("Failed to fetch Image!"));
      }
    } catch (error) {
      dispatch(imgLoadError("Failed to fetch Image!"));
      console.log("[viewImgActions.fetchImage]:", error);
    }
  };
};

const actionsTypes = {
  IMG_LOAD_START,
  IMG_LOAD_COMPLETE,
  IMG_LOAD_ERROR,
  IMG_COUNT_DOWN,
  CLEAR_LOADED_IMG
};

const actions = {
  fetchImage,
  imgCountDown
};

export { actionsTypes, actions };
