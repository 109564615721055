/* eslint-disable no-console */

// import Raven from "raven-js";
import config from "../config/config";

const init = () => {
  if (!config.isDebugMode) {
    // Raven.config("https://b1568d2cdd954d828772bc84adcc7086@sentry.io/1273015", {
    //   release: "0.0.1",
    //   environment: "dev"
    // }).install();
  }
};

const setUserContext = (id, email) => {
  if (config.isDebugMode) {
    console.log("logger-user-context", { id, email });
  } else {
    //Raven.setUserContext({ id, email });
  }
};

const exception = error => {
  if (config.isDebugMode) {
    console.log("exception >> ", exception);
  } else {
    //Raven.captureException(error);
  }
};

const info = msg => {
  if (config.isDebugMode) {
    console.log("info >> ", msg);
  } else {
    //Raven.captureMessage(msg, { level: "info" });
  }
};

const warning = msg => {
  if (config.isDebugMode) {
    console.log("warning >> ", msg);
  } else {
    //Raven.captureMessage(msg, { level: "warning" });
  }
};

const error = msg => {
  if (config.isDebugMode) {
    console.log("error >> ", msg);
  } else {
    //Raven.captureMessage(msg, { level: "error" });
  }
};

export default {
  init,
  setUserContext,
  exception,
  info,
  warning,
  error
};
