import { theme, flex, label } from "../commonStyle";

const container = {
  width: "100%",
  ...flex
};

const input = {
  // marginBottom: "10px",
  input: {
    width: "300px",
    height: "46px",
    borderRadius: "8px",
    borderColor: theme.accent,
    color: theme.accent,
    textAlign: "center",
    fontSize: "1.8rem",
    boxSizing: "unset",
    padding: "4px",
    "&::placeholder": {
      color: theme.inputPlaceHolderColor
      //fontSize: "20px",
      // lineHeight: "20px"
    },
    "&:focus": {
      outline: "none"
    }
  }
};

const labelSm = {
  ...label,
  fontSize: "1.2rem",
  padding: "2px"
};

export { label, labelSm, input, container };
