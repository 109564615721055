const theme = {
  bg: "#282c34",
  fg: "white",
  accent: "#e93578",
  inputPlaceHolderColor: "#c1c1c1",
  titleFont: "AguafinaScript",
  btnFont: "AllertaStencil"
};

const flex = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "top"
};

const label = {
  padding: "5px",
  color: theme.fg,
  fontSize: "2rem"
};

export { theme, flex, label };
