const IMG_UPDATE = "IMG_UPDATE";
const IMG_SET_VIEW_DURATION = "IMG_SET_VIEW_DURATION";
const CLEAR_IMG = "CLEAR_IMG";

const imgUpdate = img => ({
  type: IMG_UPDATE,
  img
});

const clearImg = () => ({
  type: CLEAR_IMG
});

const imgSetViewDuration = viewDuration => ({
  type: IMG_SET_VIEW_DURATION,
  viewDuration
});

export {
  IMG_UPDATE,
  IMG_SET_VIEW_DURATION,
  CLEAR_IMG,
  imgUpdate,
  imgSetViewDuration,
  clearImg
};
