import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { init as routerInit } from "./util/router";
import routes from "./config/routes";

import HomeView from "./components/Views/HomeView";
import OptionsView from "./components/Views/OptionsView";
import CodeView from "./components/Views/CodeView";
import CameraView from "./components/Views/CameraView";
import ImageView from "./components/Views/ImageView";
import LoadingView from "./components/Views/LoadingView";
import LoadImage from "./components/Views/LoadImage";
import TermsAndConitions from "./components/Views/TermsAndConitions";

class App extends Component {
  render() {
    routerInit(this.props.history);
    return (
      <Switch>
        <Route path={routes.loading} component={LoadingView} />
        <Route path={routes.loadImage} component={LoadImage} />
        <Route path={routes.options} component={OptionsView} />
        <Route path={routes.codes} component={CodeView} />
        <Route path={routes.camera} component={CameraView} />
        <Route path={routes.view} component={ImageView} />
        <Route path={routes.tac} component={TermsAndConitions} />
        <Route path={routes.home} component={HomeView} />
      </Switch>
    );
  }
}

export default App;
