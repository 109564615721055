import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import imgReducer from "./reducers/imgReducer";
import tacReducer from "./reducers/tacReducer";
import codeReducer from "./reducers/codeReducer";
import viewImgReducer from "./reducers/viewImgReducer";

const rootReducer = combineReducers({
  imgReducer,
  tacReducer,
  codeReducer,
  viewImgReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  /* preloadedState, */
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
