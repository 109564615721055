const TAC_SHOW = "TAC_SHOW";
const TAC_HIDE = "TAC_HIDE";

const tacShow = () => ({
  type: TAC_SHOW
});

const tacHide = () => ({
  type: TAC_HIDE
});

export { TAC_SHOW, TAC_HIDE, tacShow, tacHide };
