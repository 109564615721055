const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
const strReplaceAt = (str, i, replacement) =>
  str.substr(0, i) + replacement + str.substr(i + replacement.length);

const formatMinSec = sec => {
  let result = `${sec} sec`;
  if (sec > 60) {
    const _sec = sec % 60;
    const _min = Math.floor(sec / 60);
    result = `${_min} min ${_sec} sec`;
  }
  return result;
};

export { capitalize, strReplaceAt, formatMinSec };
