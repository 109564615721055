import { theme, flex } from "../../commonStyle";

const container = {
  //position: "absolute",
  backgroundColor: theme.bg,
  minHeight: "100vh",
  width: "100%",
  ...flex,
  fontSize: "calc(10px + 2vmin)",
  color: theme.fg,

  i: {
    color: "green",
    marginBottom: "15px"
  }
};

const title = {
  fontFamily: theme.titleFont,
  fontSize: "3rem",
  marginTop: "20px"
};

const text = {
  maxWith: "420px",
  padding: "10px"
};

export { container, title, text };
