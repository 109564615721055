import { theme, flex } from "../../commonStyle";

const container = {
  ...flex,
  backgroundColor: theme.bg,
  height: "100vh",
  width: "100%"
};

export { container };
